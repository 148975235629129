<template>
  <div class="preferences">
    <!-- <PatientAuthDialog ref="patientAuthDialog"></PatientAuthDialog> -->
    <v-card outlined class="box pa-4" style="min-height: 600px">
      <v-row>
        <v-col class="text-right">
          <v-btn
            title="Home"
            small
            text
            color="secondary"
            class="text-capitalize"
            :to="{ path: '/' }"
          >
            <v-icon left>mdi-arrow-left</v-icon>
            Ir para Home
          </v-btn>
        </v-col>
      </v-row>
      <LoadingContainer v-if="loading" />
      <v-form v-else ref="form" style="max-width: 500px" @submit.prevent="save">
        <v-alert text type="info">
          Para facilitar o acesso aos seus laudos, a plataforma de resultados
          Naja, permite a visualização do resultado através de um link direto
          impresso no laudo. Acessando por esse link, não é necessário informar
          um usuário e senha. Caso, não deseje que esse acesso seja possível
          para os seus laudos, desmarque a opção abaixo:
        </v-alert>
        <div class="d-flex justify-space-between">
          <span>Permitir acesso aos meus laudos através do link direto?</span>
          <v-switch
            v-model="form.allows_direct_link_to_medical_report"
            :label="form.allows_direct_link_to_medical_report ? 'Sim' : 'Não'"
            color="primary"
            class="mt-0 pa-0"
          ></v-switch>
        </div>
        <v-divider />
        <div class="d-flex justify-space-between mt-4">
          <v-btn class="cancel" elevation="0" @click.prevent="$router.go(-1)">
            <v-icon>mdi-cancel</v-icon>
            Cancelar
          </v-btn>
          <v-btn :loading="loading" elevation="0" type="submit" color="primary">
            <v-icon>mdi-content-save</v-icon>
            Salvar
          </v-btn>
        </div>
      </v-form>
    </v-card>
  </div>
</template>
<script type="text/javascript">
import LoadingContainer from '@/components/LoadingContainer'
// import PatientAuthDialog from '@/components/PatientAuthDialog'
import Api from '@/resources/NajaSocialApi'
export default {
  name: 'Preferences',
  components: { LoadingContainer },
  data() {
    return {
      loading: false,
      submitting: false,
      form: {
        allows_direct_link_to_medical_report: true,
      },
    }
  },

  mounted() {
    // this.$refs.patientAuthDialog.open('00111686', () => {
    //   console.log('Success!!!!')
    // })
    this.getSettings()
  },

  methods: {
    getSettings() {
      this.loading = true
      Api.settings()
        .then(response => {
          this.form = Object.assign(this.form, response.data.settings || {})
        })
        .catch(error => {
          this.$toast.show('Erro ao buscar preferências', 'warn')
          throw error
        })
        .finally(() => {
          this.loading = false
        })
    },

    save() {
      this.submitting = true
      const payload = { settings: this.form }
      Api.updateSettings(payload)
        .then(() => {
          this.$toast.show('Preferências salvas com sucesso', 'success')
        })
        .catch(error => {
          this.$toast.show('Erro ao salvar preferências', 'warn')
          throw error
        })
        .finally(() => {
          this.submitting = false
        })
    },
  },
}
</script>
